@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans&family=Open+Sans&display=swap');

:root {
  --dark-color: #222222;
  --light-color: #ffffff;
  --primary-color: rgb(125, 105, 217);
  --primary-color-2: rgba(195, 126, 222, 1);
}

html {
  scroll-behavior: smooth;
}

::placeholder {
  color: rgb(226, 226, 226);
  opacity: 1; /* Firefox */
  letter-spacing: .5px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--dark-color);
}

body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark-color);
  position: relative;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: 300;
}

h1>strong,
h2>strong,
h3>strong,
h4>strong,
h5>strong,
h6>strong {
  font-weight: 800;

}

p {

  font-size: 13px;
  font-weight: 100;
  line-height: 1.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* banner buttons css */
.bannerButton {
  text-align: -webkit-center;
  border-radius: 5px;
  padding: 10px 20px;
  background: var(--primary-color);
  background: linear-gradient(90deg,var(--primary-color) 0%,var(--primary-color-2) 100%);
  cursor: pointer;
  border: 1px solid var(--primary-color-2);
  color: white;
  outline: none;
  transition: 0.2s opacity ease-out;
  width: 200px;
  align-items: center;
  justify-content: center;
  

  &:hover{
    opacity: .9;
    color: white;
  }
  &:active{
    opacity: .6;
  }
}