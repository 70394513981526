@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url('./ajax-loader.gif') center center no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    bottom: -20%;

    display: block;

    padding: 10px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: black;
    border: none;
    outline: none;
    border-radius: 50%;
    box-shadow: -2px 3px 37px -10px rgba(0,0,0,0.14);
    transition: all .2s ease-out;
}

.slick-prev:hover,
.slick-next:hover{
  box-shadow: -2px 3px 37px -10px rgba(0,0,0,0.24);
  background: #ffeeff;
  transform: translate(0, -50%);
  color: darkblue;
}

.slick-prev
{
  left: 46%;
}
.slick-next
{
  left: 52%;
}
@media only screen and (max-width: 1100px) {
  .slick-next {
    left: 56%;
  }
  .slick-prev {
    left: 36%;
  }
}